var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.users_exchange,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "show-expand": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.status_id",
                fn: function ({ item }) {
                  return [
                    _c("ExchangeUserStatusCell", {
                      attrs: { status: item.status_id },
                    }),
                  ]
                },
              },
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: headers.length - 2 } },
                      [
                        _c("span", [_c("strong", [_vm._v("Балансы:")])]),
                        _c("br"),
                        _vm._l(item.balances, function (row) {
                          return _c("div", { key: row.crypto.title }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  ` ${_vm.roundToEightDecimalPlaces(
                                    row.value
                                  )} ${row.crypto.title} `
                                )
                              ),
                            ]),
                            _c("br"),
                          ])
                        }),
                        _c("span", [_c("strong", [_vm._v("Карты: ")])]),
                        _c("br"),
                        _vm._l(item.banks_cards, function (row) {
                          return _c("div", { key: row.card_number }, [
                            _c("span", [_vm._v(_vm._s(row.card_number))]),
                            _c("br"),
                          ])
                        }),
                        _c("span", [_c("strong", [_vm._v("Кошельки:")])]),
                        _c("br"),
                        _vm._l(item.wallets, function (row) {
                          return _c("div", { key: row.value }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(row.value) +
                                  " " +
                                  _vm._s(row.crypto_network.title)
                              ),
                            ]),
                            _c("br"),
                          ])
                        }),
                        _c("span", [_c("strong", [_vm._v("Другое:")])]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              `Дата создания: ${new Date(
                                Date.parse(item.created_at)
                              ).toLocaleString()}`
                            )
                          ),
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              `Дата верифицикации: ${item.user_was_verified}`
                            )
                          ),
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v("Паспорт лицевая: "),
                          _c(
                            "a",
                            { attrs: { href: item.passport_image_main } },
                            [_vm._v("скачать")]
                          ),
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v("Паспорт прописка: "),
                          _c(
                            "a",
                            {
                              attrs: { href: item.passport_image_registration },
                            },
                            [_vm._v("скачать")]
                          ),
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              `Адрес из паспорта: ${item.passport_address}`
                            )
                          ),
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(_vm._s(`Реферер: ${item.referer_code}`)),
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(`Реферальный код: ${item.referal_code}`)
                          ),
                        ]),
                        _c("br"),
                      ],
                      2
                    ),
                    _c(
                      "td",
                      { attrs: { colspan: 2 } },
                      [
                        item.status_id !== 5
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "red ma-1",
                                attrs: { dense: "", text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.ban(item)
                                  },
                                },
                              },
                              [_vm._v(" Заблокировать")]
                            )
                          : _vm._e(),
                        item.status_id === 5
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "gray ma-1",
                                attrs: { dense: "", text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.bannedToVerif(item, 3)
                                  },
                                },
                              },
                              [_vm._v(" Вернуть в верефицирован ")]
                            )
                          : _vm._e(),
                        item.status_id === 5
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "green ma-1",
                                attrs: { dense: "", text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.bannedToVerif(item, 1)
                                  },
                                },
                              },
                              [_vm._v(" Вернуть в новый ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Пользователи обменника")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Пользователи обменника</h3>
    </div>

    <div>
      <v-data-table
          :items="users_exchange"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc
          show-expand

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.status_id="{ item }">
          <ExchangeUserStatusCell :status="item.status_id"></ExchangeUserStatusCell>
        </template>


        <template v-slot:item.id="{ item }"> {{ item.id }}</template>


        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length-2">

            <span><strong>Балансы:</strong> </span><br>
            <div v-for="row in item.balances" :key="row.crypto.title">
              <span>{{ ` ${roundToEightDecimalPlaces(row.value)} ${row.crypto.title} ` }}</span><br>
            </div>

            <span><strong>Карты: </strong></span><br>
            <div v-for="row in item.banks_cards" :key="row.card_number">
              <span>{{ row.card_number }}</span><br>
            </div>

            <span><strong>Кошельки:</strong> </span><br>
            <div v-for="row in item.wallets" :key="row.value">
              <span>{{ row.value }} {{ row.crypto_network.title }}</span><br>
            </div>




            <span><strong>Другое:</strong> </span><br>
            <span>{{ `Дата создания: ${new Date(Date.parse(item.created_at)).toLocaleString()}` }}</span><br>
            <span>{{ `Дата верифицикации: ${item.user_was_verified}` }}</span><br>

            <span>Паспорт лицевая: <a :href="item.passport_image_main">скачать</a> </span><br>
            <span>Паспорт прописка: <a :href="item.passport_image_registration">скачать</a> </span><br>
            <!--span>Селфи с паспортом: <a :href="item.passport_selfie">скачать</a> </span><br-->

            <span>{{ `Адрес из паспорта: ${item.passport_address}` }}</span><br>

            <span>{{ `Реферер: ${item.referer_code}` }}</span><br>
            <span>{{ `Реферальный код: ${item.referal_code}` }}</span><br>
          </td>

          <td :colspan="2">
            <v-btn v-if="item.status_id!==5" class="red ma-1" dense text @click="ban(item)"> Заблокировать</v-btn>

            <v-btn v-if="item.status_id===5" class="gray ma-1" dense text @click="bannedToVerif(item, 3)"> Вернуть в
              верефицирован
            </v-btn>
            <v-btn v-if="item.status_id===5" class="green ma-1" dense text @click="bannedToVerif(item, 1)"> Вернуть в
              новый
            </v-btn>
          </td>

        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";
import {getHeaders} from "../store";
import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";
import ExchangeUserStatusCell from "../components/controls/ExchangeUserStatusCell.vue";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/user"

export default {
  name: 'ExchangeUsers',

  components: {ExchangeUserStatusCell, Snackbar},

  apollo: {
    $subscribe: {
      users: {
        query: gql`
 subscription MyQuery {
  users_exchange {
    verification_error
    user_was_verified
    updated_at
    status_id
    referer_code
    referal_code
    phone
    password
    passport_selfie
    passport_number
    passport_image_registration
    passport_image_main
    passport_address
    nickname
    id
    fio
    created_at
    email

    wallets {
      crypto_network {
        title
        id
      }
      value
    }

    banks_cards {
      card_number
    }

    balances(order_by: {crypto: {title: asc}}) {
      id
      value
      crypto {
        title
      }
    }

  }
}
        `,
        result({data}) {
          this.users_exchange = data.users_exchange;
        },
      },
    },
  },


  data: () => ({
    tag: 'test',
    users_exchange: [],

    headers: [
      {value: "id", text: "ID"},
      {value: "nickname", text: "Никнейм"},
      {value: "email", text: "Email"},
      {value: "fio", text: "ФИО"},
      {value: "phone", text: "Телефон"},
      {value: "status_id", text: "Статус"},

      // { value: "USDT_balance", text: "Баланс USDT" },
      // { value: "BTC_balance", text: "Баланс BTC" },

      {value: "passport_number", text: "Номер паспорта"},

      //{ value: "created_at", text: "Дата создания" },
      //{ value: "user_was_verified", text: "Дата верифицикации " },
      //{ value: "passport_image_main", text: "Основное изображение паспорта" },
      //{ value: "passport_image_registration", text: "Изображение регистрации паспорта" },
      //{ value: "passport_selfie", text: "Селфи с паспортом" },
      //{ value: "passport_address", text: "Адрес в паспорте" },

      //{ value: "updated_at", text: "Дата обновления" },
      //{ value: "verification_error", text: "Ошибка верификации" },
      //{ value: "referer_code", text: "Код реферерала" },
      //{ value: "referal_code", text: "Код реферера" },
      //{ value: "password", text: "Пароль" },
    ],
  }),

  methods: {

    roundToEightDecimalPlaces(value) {
      const multiplier = Math.pow(10, 8);
      return (Math.round(value * multiplier) / multiplier).toFixed(8);
    },

    async ban(user) {

      if (!window.confirm(`Забанить? nick: ${user.nickname} ID: ${user.id};
            `)) {

        return
      }

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,

      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/users/blocked/${user.id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async bannedToVerif(user, tostatusid) {
      if (!window.confirm(`Перевести из Заблокирован в статус ${tostatusid === 3 ? "Верифицирован" : "Новый"} ? nick: ${user.nickname} ID: ${user.id};
            `)) {

        return
      }

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({status_id: tostatusid})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/users/unblocked/${user.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    toNew() {
    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async setActive(item, active) {
      item.status = active
      //this.save(item, "edit")


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({status: active})
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)


    },

    async save(item, mode, orig) {

      let body = ""

      if (mode === 'edit') {
        let kne = this.compare2(item, orig)

        if (kne.length === 0) {
          alert("не изменено")
          return
        }

        let changed = []

        for (let k in kne) {
          changed[kne[k]] = item[kne[k]]
        }

        body = JSON.stringify(Object.fromEntries(Object.entries(changed)))
      } else
        body = JSON.stringify(item)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: body
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

    compare2(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      let kne = []

      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          kne.push(key)
        }
      }

      return kne;

    }

  },

}
</script>
